@font-face {
  font-family: 'RobotoRegular';
  src: local('Roboto Regular'), url('/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: local('Roboto Bold'), url('/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'CharterBoldItalic';
  src: local('Charter Bold Italic'), url('/fonts/charter/Charter-Bold-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CharterBold';
  src: local('Charter Bold'), url('/fonts/charter/Charter-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CharterItalic';
  src: local('Charter Italic'), url('/fonts/charter/Charter-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CharterRegular';
  src: local('Charter Regular'), url('/fonts/charter/Charter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NoeDisplayMedium';
  src: local('Noe Display Medium'), url('/fonts/FontsFree-Net-Noe-Display-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body, span{
  font-family: 'CharterRegular';
}

div {
  font-family: 'CharterRegular';
}
.title-0-2-154{
  font-family: 'RobotoBold';
  font-size: 20px;
}

h1, h2, h3, h4, h5 {
  font-family: 'CharterRegular';
}

p {
  font-family: 'CharterRegular';
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1250px;
  }
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.height150 {
  height: 150px;
}

.padding10 {
  padding: 10px;
}

.positionFixed{
  position: fixed;
}
.left0{
  left: 0;
}

.right0{
  right: 0;
}

.bottom0{
  bottom: 0;
}

.zindex600{
  z-index: 600;
}

.backgroundColorFa{
  background-color: #fafafa;
}

.displayFlex{
  display: flex
}
.justContentCenter{
  justify-content: center;
}
.alignItemsCenter{
  align-items: center;
}

.height100{
  height: 100%;
}

.pull-right {
  float: right!important;
  height: max-content !important;
}

.pull-right img {
  height: auto !important;
}

.pull-right:after {
  padding-bottom: 15;
}

div.ReactTags__tags {
  position: relative;
  font-family: Segoe-Bold;
  /* color: #d32f2f; */
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 70px;
  border-radius: 2px;
  display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
  margin-left: 0px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

a[rel="nofollow noopener"]::after {
  height: 5px;
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEASURBVHgB5ZPhkcIgEIV3iff/OjgbCMEKTivQDrQD7UBL0ArUSowVSMQCYgc2gOvimJkYiYr6z28mw/Agb5bHAvB1YHmyjeMBCPFf1qy1y9Zul26UUhHR8MbheFxLYxbFtFFeI8S2AOiXtQhx7caW1tootQei8dU/QrjBb1gQZxl6da0nRspfQBxCDQIC4GM3EbF7b8/Ths7sh2hFAE3+cq5y5tvXeMXMInY405wzPXCmf8EV8qZe1czpLlNbU+mZTMqFSRLyrik1cpXCA546siPRelrVXN/aKMq54rTQgm65Cgox52a/6tu3DH183NCbYd3FBBtyJikxEMLlrX8xJwaQX9VVF9daAAAAAElFTkSuQmCC');
  margin: 0px 1px 0px 1px;
}

@font-face {
  font-family: 'Segoe-Bold';
  src: url('fonts/Segoe-Bold.woff');
}